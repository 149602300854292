@import 'src/styles';
@import '../../../styles/mixins.module.scss';

.button_wrap {
  position: relative;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: $fixedWrapperBgColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  transition: 200ms;
  padding: 20px;

  .main_button_title {
    font-size: $manageButtonFontSize;
  }

  .bottom_button {
    @include alterativeButton;

    margin-top: 10px;
  }

  .bottom_button_title {
    @include alterativeButtonTitle;
  }

  &.static_buttons {
    background-color: transparent;
    padding: 0;
  }

  &.fix_button {
    position: fixed;

    .bottom_button {
      display: none;
    }
  }

  &.fix_link {
    .bottom_button {
      display: block;
    }
  }

  &.hidden_button_wrap {
    background-color: transparent;
    pointer-events: none;

    .main_button {
      pointer-events: auto;
    }

    .bottom_button {
      display: block;
      pointer-events: auto;
    }
  }

  &.hidden_button_wrap_fix_link {
    background-color: transparent;
  }
}

.gap {
  width: 100%;
  height: 128px;
}