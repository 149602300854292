@import 'src/styles';

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
}

.wrap {
  width: 100%;
  max-width: $maxWidthDefault;
  display: flex;
  flex-direction: column;
  align-items: center;
}
