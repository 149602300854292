@import 'src/styles';
@import "../../../styles/mixins.module.scss";

.wrap {
  padding: 15px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .top_light {
    width: 100%;
    height: 320px;
    background: radial-gradient(50% 50% at 50% 50%, #323858 0%, #101D23 100%);
    position: absolute;
    top: -18vh;
    z-index: 0;
  }

  .subtitle {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize20;
    line-height: $fontSize24;
    margin-bottom: 5px;
    color: $titleH1Color;
  }

  .text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize15;
    line-height: $fontSize20;
    color: $manageMainColor;
    letter-spacing: normal;
  }

  .bottom_button {
    font-size: $manageButtonFontSize;
    line-height: 1.1;
  }

  .reject_button {
    @include alterativeButton;

    margin-top: 10px;
  }

  .reject_button_title {
    @include alterativeButtonTitle;

    font-size: $fontSize20;
  }

  .price_text {
    color: rgba($manageMainColor, 0.8);
  }

}