@import "src/styles";

@mixin base_badge {
  width: fit-content;
  padding: 2px 10px;
  height: 20px;
  background-color: $trialsLeftBadgeBg;
  color: $trialsLeftBadgeColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fontSize12;
  font-weight: $fwsb;
  text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  top: -10px;
  z-index: 4;
}

.badge {
  @include base_badge;
  right: 20px;
}

.text {
  color: $trialsLeftTextColor;
  font-size: $fontSize10;
  font-weight: $fwb;
  text-align: right;
  margin-right: 10px;
}

.product_badge {
  @include base_badge;
  right: 10px;
}

.dinamic_btn_bage{
  @include base_badge;
  top: 5px;
  right: 25px;
}