@import "src/styles";

.wrap {
  padding: 15px;

  .title {
    padding-left: 20px;
  }

  .subtitle {
    color: $manageMainColor;
    margin-bottom: 24px;
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bottom_button {
      margin-top: 50px;
    }


  }
}