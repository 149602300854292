.apple-pay-button {
  width: 100%;
}

.apple-pay-button:disabled{
  opacity: 0.5;;
}

@supports (-webkit-appearance: -apple-pay-button) { 
  .apple-pay-button {
      display: inline-block;
      -webkit-appearance: -apple-pay-button !important;
      -apple-pay-button-style: black !important;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
      display: inline-block;
      background-size: 100% 60% !important;
      background-repeat: no-repeat !important;
      background-position: 50% 50% !important;
      border-radius: 5px;
      padding: 0px;
      box-sizing: border-box;
      min-width: 200px !important;
      min-height: 50px !important;
      max-height: 50px;
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
  }
}