@import "src/styles";

.root {
  font-size: $fontSize12;
  margin-top: 8px;
  line-height: 16px;

  b {
    font-size: $fontSize16;
  }

  svg,
  .icon {
    vertical-align: top;
    margin-right: 8px;
  }

  .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: $plansDisabledBgColor;
  }

  svg {
    path {
      fill: $plansInactiveColor;
    }
  }

  &.active svg path {
    fill: $plansPrimaryColor;
  }
}
