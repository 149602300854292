@import "src/styles";

.wrap {
  width: 100%;
  margin: 6px 0;
  position: relative;

  .container {
    width: 100%;
    border-radius: 15px;
    background: $plansInactiveBg;
    border: 2px solid $plansInactiveBorderColor;
    color: $grayOpacity;
    padding: 16px;
    overflow: hidden;
    opacity: 0.5;

    & > * {
      transition: $transition;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      text-align: left;
      line-height: 24px;
      max-width: 55%;

      .title {
        font-size: $fontSize20;
        line-height: 1.2;
        color: $plansPrimaryTitleColor;
        text-align: left;
        font-weight: 600;
      }
    }

    .features {
      color: $plansPrimaryTextColor;

      .feature {
        text-align: left;
        margin-bottom: 0.5vh;
        font-size: $fontSize14;

        svg {
          margin-right: 8px;

          path {
            fill: $plansPrimaryTitleColor;
          }
        }
      }
    }

    &.active {
      opacity: 1;
      background: $plansPrimaryBg;
      border-color: $plansPrimaryBorderColor;
      box-shadow: $boxShadow;
    }
  }

  .details {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    height: 24px;
    position: absolute;
    top: 18px;
    right: 10px;
    max-width: 44%;

    .prices {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .lineThrough {
        font-size: $fontSize15;
        text-decoration-line: line-through;
        margin-right: 8px;
        color: $plansPrimaryTextColor;
        opacity: 0.8;
      }

      .price {
        color: $plansPrimaryTextColor;
        margin-right: 8px;
        font-weight: 500;
      }
    }

    .checkmark {
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid #fff4;
      line-height: 16px;
      border-color: $plansPrimaryColor;
      background: $plansPrimaryColor;

      svg {
        width: 11px;
        height: 11px;
        margin: 2px 0 0 3px;

        path {
          fill: $plansPrimaryTextColor;
        }
      }
    }
  }

  .soldOut {
    display: none;
  }

  &.disabled {
    .soldOut {
      display: block;
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: $plansBadgeColor;
      font-weight: 600;
    }

    .checkmark {
      border: 2px solid #fff4;
      background: none;

      svg {
        path {
          fill: none;
        }
      }
    }
  }
}
