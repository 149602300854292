@import 'src/styles';

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 15px;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;

    .starsWrapper {
      display: flex;
      gap: 8px;
      margin: 0 auto;

      button {
        padding: 0;

        .starIcon {
          fill: $manageReviewStarFill;
          transition: all .3s;

          path {
            stroke: $manageReviewStarStroke;
          }

          &.active {
            fill: $manageReviewStarFillActive;

            path {
              stroke: $manageReviewStarStrokeActive;
            }
          }
        }
      }
    }

    .textarea {
      width: 100%;
      height: 110px;
      padding: 15px 18px;
      font-size: $fontSize14;
      line-height: 1.7;
      color: $manageMainColor;
      border: 1px solid $manageTextareaBorder;
      border-radius: 10px;
      background: $manageTextareaBg;
      resize: none;
      outline: none;
      caret-color: $inputCaretColor;
    }
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 40px;

    .buttonTitle {
      font-size: $manageButtonFontSize;
    }

    .submitButton {
      &:disabled {
        background: $manageReasonButtonDisabledColor;
        opacity: $manageReasonButtonDisabledOpacity;

        .buttonTitle {
          text-shadow: none;
          font-weight: $manageButtonFontWeight;
          color: $manageReasonButtonDisabledTextColor;
        }
      }
    }

    .skipButton {
      color: $manageCancelLinkColor;
      font-size: $manageLinkBtnFontSize;
    }
  }
}