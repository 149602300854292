@import "src/styles";

.bg_wrap {
  min-height: 100svh;
  background: $manageBg;

  .wrapper {
    max-width: $maxWidthFull;
    margin: 0 auto;
    position: relative;
  }
}