@import "src/styles";

.wrapper {
  margin-bottom: 32px;

  h2 {
    margin-bottom: 16px;
    font-size: $manageFeaturesTitleFontSize;
    font-family: $manageFeatureTitleFF;
  }

  .warning {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
    font-size: $manageWarningFontSize;
    color: $manageWarningColor;
    font-weight: 600;
    line-height: $fontSize22;
    font-family: $fontFamily;
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    .item {
      width: 100%;
      padding: 12px 15px;
      border-radius: $manageBorderRadius;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 5px;
      border: $manageFeatureBorder;
      background-color: $manageFeatureBgColor;

      .text_wrap {
        width: 100%;
      }

      .icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        padding: 4px;
        border-radius: 12px;
        margin-right: 10px;
        background-color: $manageFeatureCheckBgColor;

        path {
          stroke: $manageFeatureCheckIconColor;
        }
      }

      .title {
        text-align: start;
        width: 100%;
        font-family: $manageFeatureTitleFF;
        font-size: $manageFeatureTitleFontSize;
        margin-bottom: 4px;
      }

      .subtitle {
        width: 100%;
        text-align: start;
        font-family: $fontFamily;
        font-size: $manageFeatureTextFontSize;
        color: $manageFeatureSubtitleColor;
        opacity: $manageFeatureSubtitleOpacity;
      }
    }
  }
}