@import 'src/styles';

.input {
  caret-color: $inputCaretColor;
  display: flex;
  align-self: flex-start;
  border-width: 1px;
  border-style: solid;
  color: $inputColor;
  font-family: $fontFamily;
  font-size: $fontSize16;
}

.input::placeholder {
  color: $inputColor;
}

.input:focus {
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  font-size: $fontSize16 !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: $inputColor;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}