@import url('https://use.typekit.net/nms3keo.css"');

.btn {
  background: #009ee3;
  border-radius: 4px;
  max-width: 100%;
  height: 55px;
  margin-top: 16px;

  span {
    font-family: "proxima-nova", sans-serif;
    font-size: 20px;

    svg {
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
}
