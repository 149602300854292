$manageMainColor: #000000;
$manageAccentColor: $primaryColor;

$manageCPPrimaryColor: $mineShaft;
$manageCPSecondaryColor: $secondaryColor;
$manageCPTitlePrimaryColor: $manageCPPrimaryColor;

$manageBg: none;
$manageTextareaBorder: $primaryColor;
$manageTextareaBg: $manageMainColor;
$manageCheckmark: $primaryColor;
$manageCancelBorder: none;
$manageCheckboxBg: rgba($primaryColor, 0.7);
$manageDarkColor: rgba($white, 0.7);
$manageCheckboxChecked: $manageAccentColor;
$manageFeatureTextFontSize: $fontSize13;
$manageFeatureSubtitleColor: $manageMainColor;
$manageFeatureTitleFF: $fontSize22;
$mamangeSuccessModalTextFS: $fontSize16;
$manageHeaderSubtitleFS: $fontSize15;
$manageModalBg: $white;
$manageModalCloseIcon: #000000;
$manageBorderColor: rgba(255, 255, 255, 0.1);
$manageSubUpdatingBadgeBg: #F9D05D;
$managePrimaryFontFamily: Philosopher, sans-serif;
$manageSecondaryFontFamily: 'Open Sans', sans-serif;
$manageReasonButtonDisabledTextColor: #fff;
$manageReasonPopupTitleFW: 700;
$manageReasonPopupSubtitleSize: $fontSize15;
$manageReasonPopupButtonFF: $managePrimaryFontFamily;
$manageReasonPopupButtonSize: $fontSize22;
$manageReasonFS: $fontSize16;
$manageReasonLabelFF: $manageSecondaryFontFamily;
$manageTitleColor: $primaryColor;
$manageTitleFontSize: $fontSize26;
$manageTitleMarginBottom: 32px;
$manageWarningFontSize: $fontSize16;
$manageButtonFontSize: $fontSize22;
$manageWarningColor: #ee8f61;
$manageBackButtonColor: $manageCheckmark;
$manageSubtitleColor: $manageAccentColor;
$manageOverlayBg: rgba(21, 43, 53, 0.8);
$manageContentContainer: (
  'background-color': none,
  'border-radius': 0,
  'padding': 0,
);
$manageHeaderBgStyles: (
  'background': transparent
);
$manageBackArrowColor: $primaryColor;
$manageCurrentPlanBg: white;
$manageFeatureTitleFontSize: $fontSize20;
$manageFeatureBgColor: none;
$manageFeatureBorder: 2px solid var(--primaryColorLight);
$manageBorderRadius: 10px;
$manageFeatureCheckBgColor: var(--primaryColorLight);
$manageFeatureCheckIconColor: $primaryColor;
$manageCancelLinkColor: var(--primaryColor);
$manageCancelSuccessTitleColor: var(--primaryColor);
$manageCancelSuccessTitleFontSize: $fontSize32;
$manageCancelSuccessPadding: 7vh 20px 7vh;
$manageCancelSuccessHighlightTextFontWeight: 400;
$manageEmailCancelNoteBg: white;
$manageEmailCancelTextColor: $mineShaft;
$manageEmailCancelEmailColor: $primaryColor;
$manageCanceledStatusColor: #f27067;
$manageButtonFontWeight: 600;
$manageLinkBtnColor: var(--primaryColor);
$manageLinkBtnFontSize: $fontSize16;
$manageFeaturesTitleFontSize: $fontSize24;
$manageFeatureSubtitleOpacity: 0.7;
$manageCurrentPlanLeftTextSize: $fontSize16;
$manageCurrentPlanRightTextSize: $fontSize16;
$manageReasonButtonDisabledColor: $primaryColor;
$manageReasonButtonDisabledOpacity: 0.5;
$manageTitleFontWeight: $fwb;
$manageCurrentPlanTitleWeight: $fwsb;
$manageBenefitsTitleWeight: $fwstb;
$manageReasonPopupTitleSize: $fontSize28;
$manageReasonPopupButtonFS: $fontSize22;
$managePlansBadgeColor: $primaryColor;
$manageEmailCancelTitleSize: $fontSize32;

$manageAlternativeButtonBorder: none;
$manageAlternativeButtonBg: transparent;
$manageAlternativeButtonColor: white;
$manageButtonFontWeight: $fwb;
$manageAlternativeButtonFS: $fontSize22;

$manageReviewStarFill: transparent;
$manageReviewStarStroke: #D6D6D6;
$manageReviewStarFillActive: #F9D05D;
$manageReviewStarStrokeActive: #F9D05D;