@import "src/styles";

.root {
  text-decoration: none;
  span {
    color: white;
    font-weight: 600;
    text-decoration-line: underline;
    text-decoration-color: rgba(255, 255, 255, 0.7);
  }
}
