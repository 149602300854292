@import 'core/styles';

@mixin block {
  content: '';
  height: 32px;
  width: 32px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(45deg);
  border-radius: 8px 0 8px 9px;
  position: absolute;
  top: 50%;
  translate: 0 -50%;
}

.signboard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    content: '';
    width: 20%;
    height: 1px;
  }

  .content {
    border-radius: 9px;
    font-weight: $fwn;
    font-size: $fontSize16;
    width: 65%;
    text-align: center;
    padding: 10px 20px;
    position: relative;

    &:before {
      @include block;
      transform: rotate(45deg);
      left: -9px;
    }

    &:after {
      @include block;
      transform: rotate(225deg);
      right: -9px;
    }

  }
}

.warning {
  color: $warningTextColor;

  &:before {
    background-color: $warningBgColor;
  }

  &:after {
    background-color: $warningBgColor;
  }
}

.success {
  color: $successTextColor;

  &:before {
    background-color: $successBgColor;
  }

  &:after {
    background-color: $successBgColor;
  }
}

.error {
  color: $errorTextColor;

  &:before {
    background-color: $errorBgColor;
  }

  &:after {
    background-color: $errorBgColor;
  }
}


.warning .content {
  background-color: $warningBgColor;

  &:before {
    background-color: $warningBgColor;
  }

  &:after {
    background-color: $warningBgColor
  }
}

.success .content {
  background-color: $successBgColor;

  &:before {
    background-color: $successBgColor;
  }

  &:after {
    background-color: $successBgColor
  }
}

.error .content {
  background-color: $errorBgColor;

  &:before {
    background-color: $errorBgColor;
  }

  &:after {
    background-color: $errorBgColor;
  }
}