@import 'src/styles';

.vat_wrapper {

  .vat_text {
    width: fit-content;
    color: $gray;
    font-family: $fontFamily;
    font-size: $fontSize11;
    font-weight: 500;
    line-height: normal;
  }

  .table {
    margin-top: 10px;
  }

  .country {
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.05);
    padding: 2px 7px;
    margin-left: 5px;
  }

  .vat_title {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    color: $gray;
    font-family: $fontFamily;
    font-size: $fontSize11;
    font-weight: 500;
    line-height: normal;

    svg {
      margin-left: 5px;
    }

    &.collapsed {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .table {}

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: $fwb;
  }
}

.vatRow {
  margin: -2px 0 5px;

  .vatText {
    width: fit-content;
    font-weight: 500;
    font-size: $fontSize11;
    line-height: normal;
    color: $gray;
    margin-left: auto;
  }
}