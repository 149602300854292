@import 'src/styles';
@import 'core/styles/animations/_flare.scss';

.ct {
  position: relative;
  left: -200px;
  display: block;
  width: 800px;
  height: 1px;
  @include animation('flareAnimation 2s infinite')
}

.flare {
  background-color: #fff;
  width: 32px;
  height: 200px;
  top: -100px;
  right: 0;
  opacity: 0.1;
  position: absolute;
  transform: rotate(35deg);
}
