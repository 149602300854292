@import 'src/styles';

.text {
  font-size: $fontSize14;
  color: $policyCheckBoxTextColor;
  line-height: 1.5;
}

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;

  &__text {
    font-family: var(--textFontFamily);
    margin-bottom: 5px;
    text-align: center;
  }

  &__link {
    font-family: var(--textFontFamily);
    display: inline-block;
    text-decoration: underline;
  }
}