@import "src/styles";

.modal {
  width: 100%;
  max-width: $maxWidthDefault;
  height: calc(100vh - 20px);
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 17px 0;
  outline: none;
  > div {
    height: 100%;
  }
}
