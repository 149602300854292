@mixin alterativeButton {
  border: $manageAlternativeButtonBorder;
  background: $manageAlternativeButtonBg;
}

@mixin alterativeButtonTitle {
  color: $manageAlternativeButtonColor;
  font-weight: $manageButtonFontWeight;
  font-size: $manageAlternativeButtonFS;
  text-shadow: none;
}