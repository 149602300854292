@import "src/styles";

.wrap {
  width: 100%;
  margin: 6px 0;
  position: relative;

  .container {
    width: 100%;
    display: flex;
    border-radius: 15px;
    background: $plansInactiveBg;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 18px 10px;
    border: 2px solid $plansInactiveBg;

    &.big {
      padding-top: 110px;

      .discount {
        color: $plansDiscountTextColor;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        font-size: $fontSize14;
        font-weight: $fwstb;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 28px;
        background-color: $plansDiscountBgColor;
        border-radius: 15px 15px 0 0;
        padding-top: 5px;
      }
    }

    &>* {
      transition: $transition;
    }

    .leftPart {
      display: flex;
      align-items: center;
      width: 100%;

      .detailWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        font-weight: 600;
        text-align: left;

        .productTitle {
          font-size: $fontSize17;
          color: $plansTicketInactiveTextColor;
          opacity: 0.6;
          text-transform: uppercase;
          font-weight: $fwstb;
        }

        .productSubTitle {
          margin-top: 4px;
          color: $plansTicketInactiveTextColor;
          opacity: 0.5;
          font-weight: $fwb;
          font-size: $fontSize12;

          .lineThrough {
            text-decoration-line: line-through;
          }
        }

        .accentTextLineThrough {
          text-decoration-line: line-through;
          text-decoration-color: $plansBadgeColor;
          text-decoration-thickness: 2px;
          color: $plansTicketInactiveTextColor;
          opacity: 0.7;
          font-size: $fontSize14;
          font-weight: $fwb;
          position: absolute;
          right: 95px;
          bottom: 18px;
        }
      }
    }

    .accentWrap {
      position: relative;
      width: 95px;
      padding: 5px 0px;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 9.5px 8px 8px 9.5px;
      background-color: $plansInactiveColor;
      color: $plansInactivePriceColor;

      &::before {
        content: '';
        transition: $transition;
        position: absolute;
        left: -37.7px;
        border: 20.9px solid transparent;
        border-right: 21px solid $plansInactiveColor;
      }

      .accentTitle {
        display: flex;
        color: $plansTicketInactiveAccentTitleColor;
        margin-left: -10px;
        z-index: 1;

        .currencySymbol {
          font-size: $fontSize17;
          font-weight: $fwn;
        }

        .integerPart {
          font-size: $fontSize26;
          font-weight: $fwb;
          margin: -3px 2px 0;
        }

        .fractionalPart {
          font-size: $fontSize17;
          font-weight: $fwb;
        }

        .currencySuffix {
          font-size: $fontSize10;
          font-weight: $fwb;
        }
      }

      .accentSubTitle {
        font-size: $fontSize11;
        font-weight: $fwn;
        color: $plansTicketInactiveSubTitleColor;
        margin: -6px 0 0 30px;
      }
    }

    &.isActiveContainer {
      background: $plansPrimaryBg;
      border: 2px solid $plansPrimaryBorderColor;

      .detailWrap {
        .productTitle {
          opacity: 1;
        }

        .productSubTitle {
          opacity: 0.7;
        }
      }

      .accentWrap {
        background-color: $plansPrimaryColor;
        color: $plansPrimaryPriceColor;

        &::before {
          border-right: 21px solid $plansPrimaryBorderColor;
        }

        .accentTitle {
          color: $white;
          opacity: 1;
          text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.20);
        }

        .accentSubTitle {
          color: $plansTicketActiveSubTitleColor;
        }

      }

    }
  }

}