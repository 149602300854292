@import "src/styles";

.wrapper {
  margin-bottom: 24px;

  .text {
    margin-bottom: 10px;
    color: $manageCPPrimaryColor;
    font-weight: $fwsb;
    text-align: left;
    font-family: $fontFamily;

    &.main {
      margin-bottom: 18px;
    }

    &.secondary {
      color: rgba($manageCPPrimaryColor, 0.8);
      margin-bottom: 0;
      font-size: $fontSize15;
      font-weight: $fwn;
    }
  }

  .contactUs {
    padding: 0;
    color: $manageCPSecondaryColor;
    text-underline-offset: 2px;
    text-decoration: underline;
  }
}