@import "src/styles";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  min-height: 68px;
  width: 100%;
  margin: 6px 0;
  padding: 7px 24px 7px 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.00) 100%), $plansInactiveBg image-set("./images/bg@1x.webp" 1x, "./images/bg@2x.webp" 2x, "./images/bg@3x.webp" 3x) no-repeat;
  border-radius: 12px;

  &:disabled {
    cursor: default;
    opacity: 1;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
      width: 42px;
      min-width: 42px;
      height: 55px;
      min-height: 55px;
    }

    .title {
      margin-bottom: 4px;
      color: $plansSectionTitle;
      font-family: $fontFamily;
      font-size: $fontSize15;
      font-weight: $fwb;
      text-align: left;
      line-height: 1;
    }

    .subtitle {
      color: $textColor;
      font-size: $fontSize12;
      text-align: left;
      line-height: 1.3;
      opacity: .7;
    }
  }

  .price {
    color: $plansSectionTitle;
    font-size: $fontSize15;
    font-weight: $fwb;
    text-align: left;
    line-height: 1;
  }
}