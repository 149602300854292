@import "src/styles";

.overlay {
  background-color: transparent;

  @media (min-width: $desktopWidth) {
    background: $sideBarDarkBackDropBg;
  }
}

.container {
  display: flex;
  align-items: center;
  top: 0;
  z-index: 110;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  background-color: $contactUsModalBg;
  padding: 15px 15px 0;
  border-radius: 0;

  @media (min-width: $desktopWidth) {
    max-width: 500px;
    padding: 30px 15px;
  }

  .arrow_button {
    position: absolute;
    left: 20px;
    top: 10px;
    padding: 5px 15px 5px;

    .arrow {
      min-width: 14px;
      width: 14px;
      height: 22px;
      fill: $contactUsModalTitleColor;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    header {
      display: flex;

      .title {
        color: $contactUsModalTitleColor;
        font-size: $fontSize24;
        font-family: $fontFamilyPhilosopher;
        font-weight: $fwstb;
        padding-top: 5px;
      }
    }

    .tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0 0;

      .info_icon path {
        stroke: $contactUsModalSubTitleColor;
      }

      .desc {
        color: $contactUsModalSubTitleColor;
        font-size: $fontSize13;
        line-height: $fontSize20;
        padding-left: 10px;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize15;
        }
      }
    }

    .field_wrap {
      width: 100%;
      padding-top: 20px;

      .name {
        color: $contactUsModalTextColor;
        font-size: $fontSize17;
        font-weight: $fwstb;
        padding: 0 0 5px 8px;
      }

      .field {
        width: 100%;
        height: 60px;
        border-radius: 10px;
        border: 1.5px solid $contactUsModalFieldColor;
        padding-left: 10px;
        color: $contactUsModalTextColor;
        font-size: $fontSize17;
        transition: all ease 300ms;
        position: relative;
        display: flex;
        align-items: center;

        &::placeholder {
          color: $contactUsModalFieldColor;
        }

        &:focus {
          border-color: $contactUsModalActiveColor;
        }

        .placeholder {
          color: $contactUsModalFieldColor;

          &.another_color {
            color: $contactUsModalTextColor;
          }
        }

        .dropdown {
          position: absolute;
          top: 45px;
          right: -1.5px;
          z-index: 2;
          border: 1.5px solid $contactUsModalActiveColor;
          background-color: $contactUsModalSecondaryBg;
          border-radius: 0 0 10px 10px;
          padding: 5px 0 5px 10px;
          width: calc(100% + 3px);
          height: 130px;
          flex-direction: column;
          justify-content: space-between;
          display: none;
          transition: all ease 300ms;
          color: $contactUsModalTextColor;

          p {
            padding: 3px;
          }
        }

        &.active {
          border-color: $contactUsModalActiveColor;

          .dropdown {
            display: flex;
          }
        }
      }

      .message {
        height: 160px;
        background-color: $contactUsModalSecondaryBg;
        outline: none;
        padding: 10px;
      }
    }

    .button {
      font-family: $fontFamilyPhilosopher;
      background-color: var(--primaryColor);
      margin-top: 20px;
    }
  }
}