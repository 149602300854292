@import "src/styles/index";

.page-fade-enter {
  opacity: 0;
}

.page-fade-enter-active {
  opacity: 1;
  transition: opacity $pageTransitionDuration ease;
  transition-delay: $pageTransitionDelay;
}

.page-fade-exit {
  opacity: 1;
}

.page-fade-exit-active {
  opacity: 0;
  pointer-events: none;
  transition: opacity $pageTransitionDuration ease;
}
