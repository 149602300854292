@import "src/styles";

.round_checkbox {
  transition: $transition;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid $plansTicketCheckBoxBorderColor;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;

  &.checked {
    border: 2px solid $plansPrimaryBorderColor;
    opacity: 1;
    .inner_circle {
      display: block;
      background-color: $plansPrimaryBorderColor;
    }
  }

  .inner_circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: none;
    transition: $transition;
  }
}