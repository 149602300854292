@import "src/styles";

.status {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: $fontSize12;
  line-height: $fontSize16;
  text-align: center;
  white-space: break-spaces;

  &.gap {
    padding: 0 10px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 8px;
    left: 50%;
    width: 100%;
    height: $progressAfterLineHeight;
    background-color: $progressPrevLineColor;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.text {
  padding: 2px 0px 0;
}

.point {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.prev {
  color: var(--progressTextLight);

  .point {
    background: $progressAccentColor;
  }

  .center {
    width: 8px;
    height: 7px;
    background: url("icons/check.svg") no-repeat center;
    background-size: contain;
  }
}

.current {
  color: $progressCurrentTextColor;
  font-weight: $fwb;

  &::after {
    background-color: $progressLineColor;
  }

  .point {
    background: $progressNotAccentColor;
    border: 1px solid $progressAccentColor;
  }

  .center {
    width: 8px;
    height: 8px;
    background: $progressAccentColor;
    border-radius: 50%;
  }

}

.next {
  font-weight: $fwn;
  color: var(--progressTextLight);

  &::after {
    background-color: $progressLineColor;
  }

  .point {
    background: $progressNotAccentColor;
    border: 1px solid $progressAccentColor;
  }

  .center {
    width: 7px;
    height: 7px;
    background: $progressNotAccentColor;
    border-radius: 50%;
  }

}