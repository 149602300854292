@import 'src/styles';

.wrap {
  padding: 5vh 20px 5vh;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;

  &.simple {
    .context_wrap {
      margin: auto;
    }

    .note_block {
      max-width: 290px;
    }

    .title {
      margin: 20px 0;
      color: $manageTitleColor;
    }

    .text {
      color: $manageEmailCancelEmailColor;
      padding: 0 10px;

      b {
        display: none;
      }

      br {
        &:first-of-type {
          display: none;
        }
      }
    }

    .note_block {
      margin-bottom: 24px;
    }
  }

  .context_wrap {
    @media (max-height: 600px) {
      width: 80%;
    }
  }

  .close {
    position: absolute;
    right: 14px;
    top: 20px;

    svg {
      path {
        fill: $manageModalCloseIcon;
      }
    }
  }

  .title {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: 700;
    font-size: $manageEmailCancelTitleSize;
    line-height: $fontSize34;
    margin-bottom: 15px;
  }

  .text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize16;
    line-height: $fontSize22;
    color: $manageMainColor;
    letter-spacing: normal;

    @media(max-height: 600px) {
      font-size: $fontSize14;
    }

    b {
      font-weight: 600;
      color: $manageModalCloseIcon;
    }
  }

  .btn_title {
    font-size: $manageButtonFontSize;
  }

  .dark {
    color: $manageEmailCancelTextColor;
  }

  .bottom_button {
    font-size: $manageButtonFontSize;
    line-height: 1.1;
  }

  .note_block {
    border-radius: 15px;
    background: $manageEmailCancelNoteBg;

    padding: 10px;

    .note {
      color: $manageEmailCancelTextColor;
      text-align: center;
      font-family: $fontFamily;
      font-size: $fontSize14;
      font-weight: 400;
      line-height: normal;

      .email {
        font-weight: 600;
        color: $manageTextareaBorder;
      }
    }
  }

  &.small_text {
    .title {
      font-size: $fontSize26;
      line-height: $fontSize30;

      @media (max-height: 600px) {
        font-size: $fontSize22;
        line-height: $fontSize26;
      }
    }

    .text {
      font-size: $fontSize16;
      line-height: $fontSize18;

      @media (max-height: 600px) {
        font-size: $fontSize14;
        line-height: $fontSize16;
      }
    }

    .note_block {

      .note {
        font-size: $fontSize14;

        @media (max-height: 600px) {
          font-size: $fontSize12;
        }
      }
    }
  }
}