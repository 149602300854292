@import "src/styles";

.info_block {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid $colorBorderField;
  margin: 18px 0 20px;

  .text {
    font-size: $fontSize12;
    font-weight: $fwb;
    line-height: $fontSize18;
    color: $gray;

    b{
      color: var(--primaryText);
    }
  }
}