@import "src/styles";

.payment-form {
  width: 100%;
}

.form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0;

  .recurly-element-card {
    margin-top: 0;
  }

  .recurly-element,
  .recurly-element-card,
  &.StripeElement,
  input {
    @include input-style;
    font-family: $fontFamily;
    font-size: 16px;
    &::placeholder {
      font-weight: 500;
    }
  }

  label {
    font-size: $fontSize12;
    font-weight: $fwb;
    padding-bottom: 4px;
    color: $mineShaft;
  }

  &.StripeElement,
  input {
    padding: 12px;
  }

  .recurly-element-invalid {
    border-color: $colorError;
  }

  &.invalid {
    &.StripeElement,
    .recurly-element,
    .recurly-element-card,
    input {
      border-color: $colorError;
    }
  }
}

.form-field-compact {
  .recurly-element,
  .recurly-element-card,
  &.StripeElement,
  input {
    color: #545457;
    &::placeholder {
      color: rgb(155, 155, 155);
    }
  }
}

.form-button {
  height: 55px;
  display: flex;
  justify-content: center;
  background-color: $payModalPrimaryColor;
  border-radius: 25px;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.form-button-title {
  font-size: $fontSize22;
  font-weight: $fwb;
  color: $white;
}
