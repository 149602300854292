@import 'core/styles/_normalize';
@import 'core/styles/_global';

@import 'src/styles/fonts';

html,
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100svh;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden !important;
}