@import 'src/styles';

.modalContainer {
  width: 100%;
  max-width: 450px;
  max-height: 100%;
  height: calc(100% - 20px);
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 17px 0;
  outline: none;

  &_active {
    overflow-y: hidden;
  }

  &.fullMode {
    height: 100%;
    border-radius: 0;
  }
}

.hidden {
  max-height: 0;
  padding: 0;
  visibility: hidden;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 4px;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: -14px;
  height: 34px;
  width: 34px;
  background-color: $trans;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.process{
  margin-top: -10px;
  margin-bottom: 10px;
}

.contentContainer {
  width: 100%;
}