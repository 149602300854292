@import "src/styles";

.wrap {
  display: block;
  background: $manageCurrentPlanBg;
  margin-bottom: 32px;
  position: relative;
  padding: 16px 10px 10px;
  border-radius: $manageBorderRadius;
  font-family: $fontFamily;
  width: 100%;
  box-shadow: $boxShadow;

  .content_container {
    @each $property, $value in $manageContentContainer {
      #{$property}: $value;
    }
  }

  &.expanded {
    .more_button {
      transform: rotate(90deg);
    }
  }

  &.bgWithoutImg {
    background: $manageCurrentPlanBg;

    & .headerWrapper {
      justify-content: flex-start;
    }
  }

  .updatingBadge {
    padding: 4px 10px;
    font-size: $fontSize12;
    font-weight: $fwsb;
    border-radius: 10px;
    background: $manageSubUpdatingBadgeBg;
  }

  .more_button {
    position: absolute;
    transform: rotate(270deg);
    right: 15px;
    top: 16px;
  }

  .cancel_button {
    font-family: $fontFamily;
    font-size: $fontSize15;
    font-weight: 600;
    padding: 0;
    line-height: $fontSize20;
    letter-spacing: 0px;
    margin-top: 25px;
    width: 100%;
    text-align: right;
    color: var(--primaryTextLight);
  }

  .headerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    .title {
      color: $manageCPTitlePrimaryColor;
      font-weight: $manageCurrentPlanTitleWeight;
      font-size: $fontSize18;
    }
  }


  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    border-top: $manageBorderColor solid 0.5px;
    margin-top: 12px;
    padding-top: 12px;
    font-family: $fontFamily;

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    .left {
      color: $manageCPSecondaryColor;
      font-size: $manageCurrentPlanLeftTextSize;
    }

    .right {
      font-size: $manageCurrentPlanRightTextSize;
      color: white;
      text-align: right;
      color: $manageCPPrimaryColor;
      font-weight: 600;

      &.canceled {
        color: $manageCanceledStatusColor;
      }
    }
  }
}