@import "src/styles";

.root {
  display: inline-block;
  vertical-align: text-top;

  span {
    display: inline-block;
    position: relative;
    margin: 0 4px;
    padding: 3px 4px;
    border: 1px solid $plansDisabledBgColor;
    background: $plansDisabledBgColor;
    border-radius: 12px;
    font-size: $fontSize10;
    font-weight: 700;
    color: $plansDisabledTextColor;
    opacity: 0.4;

    + span {
      margin-left: -16px;
      z-index: 2;
    }
  }

  &.disabled {
    span {
      background-color: $plansDisabledBgColor;
      color: $plansDisabledTextColor;
      font-weight: 700;
    }
  }

  &:not(.disabled) span + span {
    border: 1px solid $plansBadgeColor;
    background: $plansBadgeColorLight;
    color: $plansBadgeColor;
    opacity: 1;
  }
}
