@import "./theme";

html {
  font-size: $fontSizeHtml;
}

body {
  color: $primaryText;
  font-family: $fontFamily;
}

a {
  color: $linkColor;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $fontFamily;
}

ul {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
ol {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  border: none;
  transition: $transition;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
  &:active {
    opacity: 0.7;
  }
}
