@import 'src/styles';

.reviewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0;
  height: 100%;
  transition: min-height .3s ease-in-out;

  @media (hover: hover) {
    min-height: 100svh;
  }
}