@import 'src/styles';

.header {
  width: 100%;
  padding: 0 16px;
  position: relative;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @include map-styles($manageHeaderBgStyles);
  }

  .title {
    font-family: $managePrimaryFontFamily;
    font-style: normal;
    font-weight: $manageBenefitsTitleWeight;
    font-size: $manageFeaturesTitleFontSize;
    line-height: 1.28;
    margin-bottom: 10px;
    color: $manageTitleColor;
  }

  .subtitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $manageHeaderSubtitleFS;
    line-height: 1.28;
    color: $manageSubtitleColor;
    letter-spacing: normal;
  }

  .back_button {
    position: absolute;
    top: -6px;
    left: -10px;
    padding: 10px;
    z-index: 1;

    svg {
      path {
        fill: $manageBackButtonColor;
        stroke: $manageBackButtonColor;
      }
    }
  }
}