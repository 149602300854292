@import "src/styles";

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  margin: auto;
  width: 100%;
  max-width: 450px;
  background: $fixedButtonBoxBg;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  align-content: center;
  z-index: 20;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.button_text {
  font-size: $fontSize22;
  font-weight: $fwstb;
  padding: 0 10px;
}