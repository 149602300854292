.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.field label {
  font-size: 16px;
}

.field input {
  width: 100%;
}

.button {
  cursor: pointer;
}

.invalid.submitted {
  animation-duration: .5s;
  animation-name: invalid;
  animation-timing-function: linear;
}

@keyframes invalid {
  from {
    background-color: #ff000055;
  }

  to {
    background-color: transparent;
  }
}