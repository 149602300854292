@import "src/styles";

section.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: $maxWidthDefault;

  .plansFeatures {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 24px;
    color: $plansSectionTitle;

    b {
      color: $plansPrimaryColor;
    }
  }

  &.fullScreen {
    max-width: $maxWidthFull;

    .slider {
      margin: -20px -16px 0;
      width: calc(100% + 32px);
    }
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.gap {
      margin-top: 30px;
    }
  }

  .productsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reminder {
    color: rgba(242, 230, 196, 0.7);
    text-align: center;
    padding-top: 15px;

    b {
      color: #f2e6c4;
    }
  }

  .fixedBtnWrap {
    position: relative;
    width: 100%;
  }
}

.wrap_hidden {
  width: 100%;
  position: relative;
  max-width: $maxWidthDefault;
  position: absolute;
  visibility: hidden;
}
