@import 'src/styles';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  margin: 10px 0 15px;

  &.applied {
    margin: 10px 0 5px;
  }

  .info {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize12;
    line-height: $fontSize15;
    color: $graySpecial;
    max-width: 260px;
    padding: 2px 5px 10px 0;
  }
}

.description {
  font-size: $fontSize16;
  color: $mineShaft;
  font-weight: $fwb;
  width: 20%;
  text-align: left;
}

.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  input {
    @include input-style;
    border: 2px solid $colorBorderField;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    font-weight: $fwb;
    color: $paymentModalInputTextColor;

    &.invalid {
      border-color: $colorError;
      border-style: solid;
      border-width: 1px;
    }
  }

  .formButton {
    margin-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 100%;
    min-height: 40px;
    min-width: 65px;
    width: 65px;
    background: $payModalPrimaryColor;

    &.simple_view {
      background: $injectedPaymentPrimaryColor;
    }
  }

  .formButtonTitle {
    font-size: $fontSize16;
    font-weight: $fwb;
    text-transform: uppercase;
  }
}

.rightSide {
  width: 80%;
  display: flex;
  align-items: center;
}

.editClickableWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.editEmail {
  width: 90%;
  overflow-wrap: break-word;
  text-align: right;
  padding: 0 5px;
  line-height: 24px;
  font-size: $fontSize14;
  color: $mineShaft;
  font-weight: $fwsb;
}