@import 'src/styles';

.wrap {
  padding: $manageCancelSuccessPadding;
  margin: 0 auto;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;

  .close {
    position: absolute;
    right: 14px;
    top: 20px;

    svg {
      path {
        fill: $manageModalCloseIcon;
      }
    }
  }

  .title {
    color: $manageCancelSuccessTitleColor;
    font-family: $managePrimaryFontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: $manageCancelSuccessTitleFontSize;
    line-height: $fontSize34;
    margin-bottom: 15px;
  }

  .text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize16;
    line-height: $fontSize22;
    color: $manageMainColor;
    letter-spacing: normal;

    b {
      font-weight: $manageCancelSuccessHighlightTextFontWeight;
    }
  }

  .bottom_button {
    font-size: $manageButtonFontSize;
    line-height: 1.1;
  }
}