@import "src/styles";

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;

  &.simple {
    .divider {
      &::before {
        left: -15px;
        width: calc(100% + 30px);
      }
    }
  }
}

.header {
  max-width: 325px;

  .totalPrice {
    margin-bottom: 15px;
  }
}

.title {
  font-family: $fontFamily;
  font-size: $fontSize18;
  font-weight: $fwb ;
  line-height: 1.36;
  letter-spacing: 0px;
  text-align: center;
  color: $titlePaymentModal;
  margin-bottom: 15px;

  b {
    font-weight: $fwstb;
    color: $titlePaymentModalSecondary;
  }
}

.wrap {
  width: 100%;
  max-width: $maxWidthDefault;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.simple_title {
    background-color: #F6F6F6;
    border-radius: 15px;
    padding: 15px;
  }

  .reminder {
    border-radius: 10px;
    border: 1px solid rgba(184, 185, 195, 0.3);
    color: $gray;
    text-align: left;
    padding: 10px 15px;
    margin-top: 15px;

    b {
      color: $mineShaft;
    }
  }
}

.formWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  align-items: flex-start;
  text-align: left;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 1px 8px 0 $boxShadowColor;
  border: 2px solid $payModalPrimaryColor;

  &.simple_view {
    border-color: $injectedPaymentPrimaryColor;
  }
}

.formWrapOpen {
  padding: 21px 16px 25px;
}

.payWithCard {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  width: 100%;

  .icon_card path {
    &:first-child {
      fill: $payModalPrimaryColor;
    }
  }
}

.payWithCardText {
  font-size: $fontSize15;
  font-weight: $fwb;
  margin-left: 12px;
  color: $mineShaft;
}

.arrowDownWrap {
  width: 30px;
  height: 30px;
  background-color: var(--payModalPrimaryColorLight);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
}

.iconArrow {
  path {
    fill: $payModalPrimaryColor;
    stroke: $payModalPrimaryColor;
  }
}

.iconArrowUp {
  transform: rotate(180deg);
}

.expandWrap {
  width: calc(100% + 10px);
  max-height: 0;
  overflow: hidden;
  padding: 0 5px;
  margin-left: -5px;
  transition: $transition;
}

.expand {
  max-height: 800px;
  margin-top: 6px;
}

.cardsList {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 10px;
}

.cardItem {
  display: flex;
  width: 23px;
  margin: 0 2px;
}

.appleButton {
  margin-top: 16px;
}

.paypal {
  width: 100%;
  height: 55px;
  margin-top: 16px;
}

.or {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;

  p {
    font-size: $fontSize15;
    font-weight: $fwb;
    padding: 0 10px;
    color: $gray;
    background-color: transparent;
  }

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    width: calc((100% - 45px) / 2);
    height: 1px;
    background-color: $colorBorderField;
  }

  &::after {
    content: "";
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    width: calc((100% - 45px) / 2);
    height: 1px;
    background-color: $colorBorderField;
  }
}

.divider {
  width: 100%;
  height: 1px;
  position: relative;
  margin-top: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -28px;
    width: calc(100% + 56px);
    height: 1px;
    background-color: $colorBorderField;
  }
}

.payment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .price {
    margin-top: 15px;
    margin-right: 10px;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    span:last-child {
      font-weight: 600;
      margin-left: 5px;
      color: $secondaryColor;
    }
  }
}

.space {
  height: 10px;
}

.column {
  flex-direction: column;
}

.paymentMethods {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}