@import "src/styles";

.progress {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  font-family: $fontFamily;

  &.narrow {
    width: auto;
  }
}