@import 'src/styles';

.wrapper {
  position: relative;
  width: 100%;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 15px 25px 15px 10px;
  font-family: $fontFamily;
  font-size: $fontSize18;
  color: $selectInputTextColor;
  border: 1px solid $selectInputBorder;
  background: $selectInputBgColor;
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
}

.icon {
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 10px;
  color: $selectInputBorder;
  translate: 0 -50%;
  z-index: 2;
}

.loader {
  position: absolute;
  pointer-events: none;
  top: 35%;
  right: 10px;
  @include animation('preloader 1s linear infinite');
  width: 20px;
  height: 20px;

  svg circle {
    fill: none;
    stroke: $preloaderColor;
  }
}

@include keyframes(preloader) {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}