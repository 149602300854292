@import 'src/styles';

.wrap {
  width: 100%;
  display: flex;
  max-height: 90vh;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  padding: 15px 25px 20px;

  .img {
    max-width: 345px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .title {
    color: #FAF6E8;
    text-align: center;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize24;
    font-style: normal;
    font-weight: 700;
    line-height: $fontSize30;
    margin-bottom: 15px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 15px;
    background: #1E3B48;
    padding: 15px;

    &.withFooter {
      border-radius: 15px 15px 0 0;
    }
  }

  .subtitle {
    color: rgba($white, 0.85);
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-weight: 400;
    line-height: $fontSize22;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 20px;
  }

  .text {
    font-family: $fontFamily;
    font-size: $fontSize15;
    font-weight: 400;
    line-height: $fontSize22;
    letter-spacing: 0px;
    text-align: center;
  }

  .footerText {
    color: rgba($white, 0.80);
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .highlight {
      font-weight: 600;
      color: $white;
    }
  }

  .footer {
    background: #1E3B48;
    width: 100%;
    border-radius: 0 0 15px 15px;
    margin-top: 1px;
    padding: 15px 15px 25px 15px;
  }

  .btn {
    margin-top: 20px;
  }

  .btnTitle {
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize22;
    font-weight: 700;
    line-height: $fontSize26;
    letter-spacing: 0px;
    text-align: center;
  }
}