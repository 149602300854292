@import 'src/styles';

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-top: 3px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: $checkboxMarkBackground;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: $transition;

    &::after {
      content: "";
      position: absolute;
      display: none;
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }

  input:checked ~ .checkbox__mark {
    background-color: $checkboxMarkCheckedBackground;
  }

  input:checked ~ .checkbox__mark::after {
    display: block;
  }
}