@import "src/styles";

.overlay {
  position: fixed;
  z-index: 2;
  inset: 0;
  background: rgba(21, 43, 53, 0.8);
  display: flex;

  .modal {
    background: $manageModalBg;
    border-radius: 20px;
    display: flex;
    align-items: center;
    outline: none;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    max-width: 335px;
    padding: 40px 20px 30px;
    top: 0;
    height: fit-content;
    margin: auto;

    .title {
      font-family: $fontFamilyPhilosopher;
      font-style: normal;
      font-weight: 700;
      font-size: $fontSize24;
      line-height: $fontSize28;
      white-space: pre-line;
      margin-bottom: 24px;
    }

    .text {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: $fontSize20;
      letter-spacing: normal;
      line-height: $fontSize22;
      color: $manageMainColor;
      margin-bottom: 32px;
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;

      svg {
        path {
          fill: $manageModalCloseIcon;
        }
      }
    }

    .button {
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 270px;

      .button__title {
        font-family: $managePrimaryFontFamily;
        font-style: normal;
        font-weight: 700;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
        font-size: $fontSize22;
        line-height: $fontSize26;
        color: $white;
      }
    }
  }
}
