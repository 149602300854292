@import 'src/styles';

.wrap {

  .title {
    text-align: left;
    margin-bottom: 15px;
    font-size: $fontSize22;
    line-height: $fontSize32;
    letter-spacing: -0.567px;
  }

  .text_footnote {
    font-size: $fontSize12;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 15px;
    color: $textColor;

    b {
      font-weight: 700;
    }
  }

  .text {
    font-size: $fontSize16;
    font-weight: 600;
    line-height: $fontSize22;
    letter-spacing: 0px;
    text-align: left;
    color: $textColor;
  }


  .price {
    display: flex;
    justify-content: space-between;
    color: $textColor;
    font-size: $fontSize16;
    font-weight: 700;
    line-height: $fontSize22;
    letter-spacing: 0px;
    text-align: right;
  }

  .week_price {
    color: $textColor;
    font-size: $fontSize16;
    font-weight: 700;
    line-height: $fontSize22;
    letter-spacing: 0px;
    text-align: right;
  }

  .week {
    color: $plansDisabledBgColor;
    text-align: right;
    font-size: $fontSize12;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .price_wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $plansDisabledBgColor;


    .lineThrough {
      font-size: $fontSize16;
      font-weight: 600;
      line-height: $fontSize22;
      letter-spacing: 0px;
      text-align: right;
      margin-right: 5px;
      text-decoration: line-through;
      color: $plansDisabledBgColor;
      opacity: 0.6;
    }
  }
}