@import 'src/styles';

.optionsWrapper {
  margin-bottom: 1.5vh;

  .title {
    margin-bottom: 2vh;
    color: $primaryText;
    font-family: $fontFamily;
    font-size: $fontSize18;
    font-weight: $fwstb;
    line-height: 1.4;
    text-align: center;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;

    .option {
      position: relative;
      padding-left: 18px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 8px;
        height: 8px;
        background-color: var(--primaryColor);
        border-radius: 50%;
      }

      .optionText {
        width: 100%;
        font-size: $fontSize13;
        font-family: $fontFamily;
        line-height: $fontSize22;
        color: $primaryText;
        text-align: left;
      }
    }
  }
}