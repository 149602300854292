@import "src/styles";

.overlay {
  background: $sideBarDarkBackDropBg;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;

  .popup {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: $contactUsModalBg;
    width: 300px;
    height: 250px;
    border-radius: 15px;
    padding: 20px;

    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        .done_icon{
          fill: $contactUsModalDoneIcon;
        }
      }

      .title {
        font-size: $fontSize16;
        color: $contactUsModalTextColor;
        font-weight: $fwstb;
        text-align: center;
        padding-left: 5px;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize20;
        }
      }
    }

    .text {
      font-size: $fontSize16;
      color: $contactUsModalTextColor;
      text-align: center;

      @media (min-width: $desktopWidth) {
        font-size: $fontSize20;
      }
    }

    .button {
      background-color: var(--primaryColor);
    }
  }

}