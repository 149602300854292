@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;

  .title {
    @include title-h1;
    margin: 1vh 0;
    width: 96%;
    font-size: $fontSize24;
    font-weight: $fwstb;
    color: $primaryText;
    line-height: normal;

    b {
      color: $secondaryColor;
    }
  }

  .subtitle {
    max-width: $maxWidthDefault;
    margin: 1vh 0 0;
    font-weight: $fwstb;
    font-family: $fontFamily;
    font-size: $fontSize18;
    color: $primaryText;
    text-align: center;
    line-height: 1.45;
    letter-spacing: 0;
  }

  .image {
    height: 100%;
    max-height: 100px;
  }
}