@import "src/styles";

.iframe_modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.iframe {
  width: 100%;
  height: 100%;
}