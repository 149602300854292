@import 'src/styles';

.wrap {
  position: relative;
  padding: 15px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $manageBg;

  .title {
    padding-left: 20px;
  }
}

.bottom {
  display: flex;
  justify-content: center;
  width: 100%;

  .bottom_button {
    &:disabled {
      background: $manageReasonButtonDisabledColor;
      opacity: $manageReasonButtonDisabledOpacity;
      box-shadow: none;

      .button {
        text-shadow: none;
        color: $manageReasonButtonDisabledTextColor;
      }
    }
  }

  .button {
    font-size: $manageButtonFontSize;
    line-height: 1.1;
    font-weight: $manageButtonFontWeight;
  }
}