@import "src/styles";

.status {
  position: relative;
  display: flex;
  align-items: center;
  font-size: $fontSize12;
  line-height: $fontSize16;
  text-align: center;
  margin: 0 10px;

  &::after {
    content: '';
    position: absolute;
    top: 6px;
    right: -17px;
    width: 12px;
    height: 12px;
    background: url(./icons/right.svg);
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.prev {
  color: var(--primaryText);
  opacity: 0.6;

  &::after {
    opacity: 0.6;
  }
}

.current {
  color: var(--secondaryText);
  font-weight: $fwb;
  background-color: var(--primaryColor);
  border-radius: 20px;
  padding: 4px 8px;

  &::after {
    right: -15px;
  }
}

.next {
  font-weight: $fwn;
  color: var(--primaryText);
}