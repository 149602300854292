@import "src/styles";

.swiper {

  .swiper-pagination-bullet {
    background-color: $grayLight;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 8px;
    margin: 0 3px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: var(--primaryColor);
  }
}