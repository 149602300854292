@import 'src/styles';

.wrapper {
  max-width: $maxWidthDefault;
  margin: 0 auto;
  position: relative;

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
  }

  iframe {
    position: relative;
    top: 45px;
    left: 0;
    height: 85vh;
  }

  &.dark {
    .close {
      path {
        fill: #FAE6C4;
      }
    }
  }
}