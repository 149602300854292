@import "src/styles";

.wrap {
  width: 100%;
}

.button {
  bottom: 40px;
  position: absolute;
  background-color: var(--primaryColor);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
