.wrapper {
  background: #ffc439;
  border-radius: 4px;
  position: relative;

  &.notActive {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    cursor: not-allowed;
    min-height: 55px;
  }

  .spinner {
    position: absolute;
    height: 100%;
    background: none;

    >* {
      scale: 0.75;
    }
  }
}