@import "src/styles";

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  font-weight: $fwb;

  .with_margin {
    margin-top: 15px;
  }
}

.features {
  margin: 16px 0 8px;

  .feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .feature_text {
      font-family: $fontFamily;
      font-size: $fontSize12;
      font-weight: $fwn;
      line-height: $fontSize16;
      text-align: left;
      color: var(--textColor);
    }

    .icon {
      margin-right: 8px;
    }

    .feature_price {
      font-weight: 500;
    }

    .dots {
      height: 9px;
      margin: 0 4px;
      flex-grow: 1;
      border-bottom: 1px dotted $plansDotsColor;
    }
  }
}

.vatRow {
  margin: -2px 0 5px;

  .vatText {
    font-weight: 500;
    font-size: $fontSize11;
    line-height: normal;
    color: $gray;
    margin-left: auto;
  }
}

.aboutItemBlackText {
  color: $mineShaft;
  font-size: $fontSize13;
  line-height: 1.38;

  .lineThrough {
    color: $paymentModalLineThroughPriceColor;
    margin-right: 5px;
  }

  .smallText {
    font-size: $fontSize14;
  }

  &.largeText {
    font-size: $fontSize15;
  }
}

.full_price {
  text-decoration: line-through;
  color: $titlePaymentModalSecondary;
  margin-right: 5px;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.simple {
    .divider {
      &::before {
        width: calc(100% + 30px);
        left: -15px;
      }
    }
  }

  .block {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .promo_discount {
    display: flex;
    justify-content: space-between;
    color: #16786C;
    font-family: $fontFamily;
    font-size: $fontSize15;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .discount_price {
      display: inline;
      white-space: nowrap;
    }
  }

  .promocode {
    color: #F27067;
    font-family: $fontFamily;
    font-size: $fontSize13;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    span {
      font-size: $fontSize24;
      margin-right: 5px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -28px;
      width: calc(100% + 56px);
      height: 1px;
      background-color: $colorBorderField;
    }
  }

  .with_padding {
    padding-bottom: 20px;
  }

  .with_border {
    border-bottom: 1px solid rgba(#B8B9C3, 0.3);
  }

  .headerWrapper {
    margin: 1vh 0;

    .modalTitle {
      text-align: left;
      font-family: $fontFamily;
      font-weight: $fwb;
      font-size: $fontSize13;
      color: $gray;
    }

    .modalSubtitle {
      font-size: $fontSize12;
      color: $gray;
    }
  }

  .discountTitle {
    font-size: $fontSize17;
    font-weight: $fwb;
    color: $payModalPrimaryColor;
  }

  .discount {
    background-color: $payModalDiscountColor;
    padding: 10px 16px;
    font-size: $fontSize18;
    font-weight: $fwb;
    line-height: 1;
    border-radius: 6px;
    color: white;
  }

  .aboutItemText {
    font-size: $fontSize15;
    line-height: $fontSize20;
    color: $mineShaft;
  }

  .aboutItemGrayText {
    color: $gray;
    font-family: $fontFamily;
    font-size: $fontSize14;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.smallText {
      font-size: $fontSize12;
      font-weight: $fwsb;
    }
  }

  .aboutItemDiscountText {
    width: 100%;
    color: $paymentDiscount;
    text-align: right;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .discount_text {
    color: $paymentDiscount;
  }

  .lineThrough {
    text-decoration-line: line-through;
    color: $paymentModalLineThroughPriceColor;
    font-weight: 700;

    &.disabled {
      font-weight: inherit;
      color: $paymentModalLineThroughPriceColor;
      opacity: $paymentModalLineThroughPriceOpacity;
    }
  }

  .specialOffer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;

    .title {
      font-weight: 600;
      font-size: $fontSize17;
      line-height: $fontSize23;
      color: $secondaryColor;
    }

    .text {
      background: var(--primaryColor);
      border-radius: 6px;
      padding: 10px;
      font-weight: 700;
      font-size: $fontSize12;
      line-height: $fontSize16;
      color: $white;
    }
  }

  .leftColumn {
    display: flex;
    align-items: center;
  }

  b {
    color: $secondaryColor;
    color: $paymentModalPersonalDiscountColor;
    font-weight: 600;
  }

  .totalPrice {
    width: 100%;
  }
}