@import "src/styles";
@import "../../../styles/mixins.module.scss";

.wrap {
  padding: 16px;
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100svh;

  h1 {
    margin-bottom: $manageTitleMarginBottom;
    color: $manageTitleColor;
    font-size: $manageTitleFontSize;
    font-weight: $manageTitleFontWeight;
  }

  .btnTitle {
    font-weight: $manageButtonFontWeight;
    font-size: $manageButtonFontSize;

    &.secondary_button,
    &.cancel {
      @include alterativeButtonTitle;
    }
  }

  .btn {
    margin-bottom: 10px;
    color: $manageCancelLinkColor;
    font-size: $manageLinkBtnFontSize;
    border: $manageCancelBorder;

    &.no_features {
      margin-top: auto;
    }

    &.secondary_button {
      @include alterativeButton;
    }
  }
}

.back_button {
  position: absolute;
  top: 12px;
  left: 10px;
  padding: 10px;
  z-index: 10;

  svg {
    path {
      fill: $manageBackArrowColor;
      stroke: none;
    }
  }
}