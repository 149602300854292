@import 'src/styles';

.switch_checkbox {
  display: none;
}

.switch_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 55px;
  height: 36px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  background-color: $switchBgOff;

  &.on {
    background-color: $switchBgOn;
  }
}

.switch_label .switch_button {
  content: '';
  position: absolute;
  top: 5px;
  left: 3px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: 0.2s;
  background: $switchMarker;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch_checkbox:checked+.switch_label .switch_button {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}